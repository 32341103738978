var _require = require('preact'),
    h = _require.h;

var copyToClipboard = require('../../../utils/copyToClipboard');

var _require2 = require('../../icons'),
    iconPencil = _require2.iconPencil,
    iconCross = _require2.iconCross,
    iconCopyLink = _require2.iconCopyLink;

var renderEditButton = function renderEditButton(props) {
  return !props.uploadInProgressOrComplete && props.metaFields && props.metaFields.length > 0 && h("button", {
    class: "uppy-u-reset uppy-DashboardItem-action uppy-DashboardItem-action--edit",
    type: "button",
    "aria-label": props.i18n('editFile') + ' ' + props.file.meta.name,
    title: props.i18n('editFile'),
    onclick: function onclick(e) {
      return props.toggleFileCard(props.file.id);
    }
  }, iconPencil());
};

var renderRemoveButton = function renderRemoveButton(props) {
  return props.showRemoveButton && h("button", {
    class: "uppy-u-reset uppy-DashboardItem-action uppy-DashboardItem-action--remove",
    type: "button",
    "aria-label": props.i18n('removeFile'),
    title: props.i18n('removeFile'),
    onclick: function onclick() {
      return props.removeFile(props.file.id);
    }
  }, iconCross());
};

var copyLinkToClipboard = function copyLinkToClipboard(event, props) {
  return copyToClipboard(props.file.uploadURL, props.i18n('copyLinkToClipboardFallback')).then(function () {
    props.log('Link copied to clipboard.');
    props.info(props.i18n('copyLinkToClipboardSuccess'), 'info', 3000);
  }).catch(props.log) // avoid losing focus
  .then(function () {
    return event.target.focus({
      preventScroll: true
    });
  });
};

var renderCopyLinkButton = function renderCopyLinkButton(props) {
  return props.showLinkToFileUploadResult && props.file.uploadURL && h("button", {
    class: "uppy-u-reset uppy-DashboardItem-action uppy-DashboardItem-action--copyLink",
    type: "button",
    "aria-label": props.i18n('copyLink'),
    title: props.i18n('copyLink'),
    onclick: function onclick(event) {
      return copyLinkToClipboard(event, props);
    }
  }, iconCopyLink());
};

module.exports = function Buttons(props) {
  return h("div", {
    className: "uppy-DashboardItem-actionWrapper"
  }, renderEditButton(props), renderCopyLinkButton(props), renderRemoveButton(props));
};