var _require = require('preact'),
    h = _require.h;

var AddFiles = require('./AddFiles');

var AddFilesPanel = function AddFilesPanel(props) {
  return h("div", {
    class: "uppy-Dashboard-AddFilesPanel",
    "data-uppy-panelType": "AddFiles",
    "aria-hidden": props.showAddFilesPanel
  }, h("div", {
    class: "uppy-DashboardContent-bar"
  }, h("div", {
    class: "uppy-DashboardContent-title",
    role: "heading",
    "aria-level": "h1"
  }, props.i18n('addingMoreFiles')), h("button", {
    class: "uppy-DashboardContent-back",
    type: "button",
    onclick: function onclick(ev) {
      return props.toggleAddFilesPanel(false);
    }
  }, props.i18n('back'))), h(AddFiles, props));
};

module.exports = AddFilesPanel;